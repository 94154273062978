export function authcase(caso) {
  let mensagem = String;
  switch (caso) {
    case "auth/user-not-found":
      mensagem = "Usuário não encontrado.";
      break;
    case "auth/wrong-password":
      mensagem = "Senha incorreta";
      break;
    case "auth/invalid-email":
      mensagem = "Email inválido.";
      break;
    case "auth/missing-email":
      mensagem = "Digite um email";
      break;
  }
  return mensagem;
}
