<template>
  <div
    class="tw-h-full tw-bg-gradient-to-br tw-from-pink-600 tw-to-pink-800 tw-flex tw-items-center"
  >
    <div class="tw-mx-auto tw-w-full">
      <div
        class="tw-text-center tw-font-bold tw-m-auto white--text tw-text-4xl tw-py-5 tw-pb-10"
      >
        Login
      </div>
      <div
        class="tw-p-4 tw-w-auto sm:tw-w-3/4 sm:tw-max-w-md tw-mx-2 sm:tw-mx-auto tw-rounded-xl tw-shadow-md tw-bg-white"
      >
        <img
          src="@/assets/logo.png"
          class="tw-w-2/6 tw-px-2 tw-py-3 tw-mx-auto"
        />
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
          @submit.prevent="acesso_login"
          class="px-0"
        >
          <div class="px-2">
            <v-text-field
              label="Email"
              v-model="email"
              name="input-email"
              :rules="[rules.required, rules.emailMatch]"
              placeholder="email@provider.com"
            />
            <v-text-field
              v-model="password"
              :append-icon="viewPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="[rules.required, rules.min]"
              :type="viewPassword ? 'text' : 'password'"
              name="input-password"
              label="Digite sua senha"
              @click:append="viewPassword = !viewPassword"
            ></v-text-field>
          </div>
          <div class="tw-grid tw-grid-cols-2 tw-items-center">
            <div
              class="tw-order-2 sm:tw-order-1 tw-col-span-2 py-4 sm:py-0 sm:tw-col-span-1 tw-text-center sm:tw-text-left"
            >
              <a
                class="tw-text-pink-700 hover:tw-underline tw-ml-0 sm:tw-ml-4"
                @click="modalResetPassword = true"
              >
                Esqueceu a senha?
              </a>
            </div>
            <div
              class="tw-text-center sm:tw-text-right tw-col-span-2 tw-order-1 sm:tw-order-2 py-2 sm:py-0 sm:tw-col-span-1"
            >
              <button
                class="btnPadrao tw-w-full sm:tw-w-1/2"
                :class="{ btnDesativado: !valid, btnAtivo: valid }"
              >
                <div v-if="loading" class="tw-animate-spin">
                  <v-icon class="tw-fill-current tw-text-white"
                    >mdi-loading</v-icon
                  >
                </div>
                <div v-else>Entrar</div>
              </button>
            </div>
          </div>
        </v-form>
      </div>
      <div class="tw-text-white tw-font-light tw-text-center tw-py-5">
        Acesso ao painel administrativo
      </div>
      <div>
        <v-dialog persistent v-model="modalResetPassword" max-width="500px">
          <div class="tw-p-6 tw-px-7 tw-bg-white">
            <div class="tw-grid tw-grid-cols-4">
              <div class="tw-text-2xl tw-font-light tw-col-span-3">
                Esqueci minha senha
              </div>
              <div class="tw-col-span-1 tw-text-right">
                <v-btn text @click="modalResetPassword = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>
            </div>
            <div>
              <v-text-field
                label="Email"
                id="id"
                v-model="emailModal"
                :rules="[rules.required, rules.emailMatch]"
              />
              <div class="tw-text-right">
                <button
                  class="tw-px-4 tw-py-3 tw-bg-primary tw-rounded-md tw-text-white tw-fill-current"
                  @click="esqueci_senha()"
                >
                  <div v-if="loading">
                    <v-icon class="tw-animate-spin">mdi-loading</v-icon>
                  </div>
                  <div v-else>Enviar</div>
                </button>
              </div>
            </div>
          </div>
        </v-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getAuth,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
} from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import { db } from "@/service/firebase";
import { authcase } from "@/constants/translaterErrorFirebase.js";

export default {
  data() {
    return {
      valid: false,
      viewPassword: false,
      loading: false,
      email: "",
      emailModal: "",
      password: "",
      modalResetPassword: false,
      rules: {
        required: (value) => !!value || "obrigatório.",
        min: (v) => v.length >= 8 || "Mínimo de 8 caracteres ",
        emailMatch: (v) =>
          this.validaEmail(v) || `O email que você digitou não corresponde.`,
      },
    };
  },
  mounted() {
    this.$store.commit("dashboard/erase");
    this.$store.commit("user/erase");
  },
  methods: {
    validaEmail(email) {
      var re = /\S+@\S+\.\S+/;
      return re.test(email);
    },
    async verificaTipoDeUsuario(token) {
      const docRef = doc(db, "users", `${token}`);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        let payload = docSnap.data();
        this.$store.commit("user/setUserPayload", {
          nome: payload.nome,
          role: payload.role,
          franqueado_id: payload.franqueado_id,
          email: payload.email,
        });
        this.loading = false;
        this.$store.commit("user/setAuth", true);
        this.$store.commit("user/setToken", token);
        this.$router.push("/dashboard");
      } else {
        this.$store.commit("user/setAuth", false);
        this.$root.infoModal("Infelizmente você não tem acesso ao dashboard");
      }
    },
    acesso_login() {
      if (!this.valid) {
        return false;
      }
      this.loading = true;
      const auth = getAuth();
      signInWithEmailAndPassword(auth, this.email, this.password)
        .then((userCredential) => {
          const user = userCredential.user;
          this.verificaTipoDeUsuario(user.uid);
        })
        .catch((error) => {
          const errorCode = error.code;
          console.error(errorCode);
          let msg = authcase(errorCode);
          this.$root.errorModal(msg);
          this.loading = false;
        });
    },
    esqueci_senha() {
      const auth = getAuth();
      sendPasswordResetEmail(auth, this.emailModal)
        .then(() => {
          this.modalResetPassword = false;
          this.$root.sucessoModal("Email para redefinição de senha enviado!");
        })
        .catch((error) => {
          const errorCode = error.code;
          console.log(errorCode);
          let msg = authcase(errorCode);
          this.$root.errorModal(msg);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.btnPadrao {
  @apply tw-px-4 tw-py-3 tw-rounded tw-text-white;
}
.btnAtivo {
  @apply tw-bg-pink-600 tw-cursor-pointer focus:tw-ring-pink-200 focus:tw-ring-4 tw-shadow;
}
.btnDesativado {
  @apply tw-bg-gray-300 tw-cursor-not-allowed focus:tw-ring-pink-200 focus:tw-ring-0 tw-shadow-sm;
}
</style>
